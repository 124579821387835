const routes = [
    {
        path: '',
        name: 'provider-dashboard',
        component: () => import(/* webpackChunkName: "provider" */ '@/view/dashboard/ProviderDashboard.vue'),
    }, {
        path: 'consults/:consultId/view',
        name: 'consult-view',
        props: true,
        component: () => import(/* webpackChunkName: "consult-view" */ '../view/apps/provider/consults/Consult.vue')
    },
    {
        path: 'consults/:consultId/upload',
        name: 'consult-upload',
        props: true,
        component: () => import(/* webpackChunkName: "consult-upload" */ '../view/apps/provider/consults/UploadRecords.vue')
    }, {
        path: 'consults',
        name: 'consults',
        components: {
            default: () => import(/* webpackChunkName: "consults" */ '../view/apps/provider/consults/Consults.vue'),
        }
    },
    {
        path: 'texts/:tsId/view',
        name: 'text-session-view',
        props: true,
        component: () => import(/* webpackChunkName: "text-session-view" */ '../view/apps/provider/texts/TextSession.vue'),
    },
    {
        path: 'texts',
        name: 'text-sessions',
        props: true,
        component: () => import(/* webpackChunkName: "text-sessions" */ '../view/apps/provider/texts/Texts.vue'),
    },
    {
        path: 'events/:eventId',
        name: 'event-view',
        component: () => import(/* webpackChunkName: "event-view" */ '../view/apps/provider/events/Event.vue'),
        props: true
    },
    {
        path: 'events/:eventId/team',
        name: 'event-team',
        props: true,
        component: () => import(/* webpackChunkName: "event-team" */ '@/view/apps/provider/events/Team.vue'),
    },
    {
        path: 'events/:eventId/team/:memberId/assess',
        name: 'wellness-assess',
        props: true,
        component: () => import(/* webpackChunkName: "wellness-assess" */ '../view/apps/provider/events/WellnessAssessment.vue'),
    },
    {
        path: 'events/:eventId/team/:memberId/consult',
        name: 'wellness-consult',
        props: true,
        component: () => import(/* webpackChunkName: "wellness-consult" */ '../view/apps/provider/events/WellnessConsult.vue'),
    },
    {
        name: 'events',
        path: 'events',
        component: () => import(/* webpackChunkName: "events" */ '../view/apps/provider/events/Events.vue'),
    },
    {
        path: 'copilot',
        name: 'copilot',
        component: () => import(/* webpackChunkName: "copilot" */ '../view/apps/provider/Copilot.vue'),
    },
    {
        path: 'profile',
        name: 'view-provider',
        component: () => import(/* webpackChunkName: "view-provider" */ '../view/apps/users/ViewAdmin.vue'),
    },
];

export default routes;
